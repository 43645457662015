.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/*
.classParentString {
  background-color: black;

}
*/

.hoverspan:hover {
  color: red;
}
.className {
  background-color: #a1dcff;
}

.className1 {
  display:flex;
  align-items:center;
  height:45px;
  background-color:#F3F3F3;
}


.openedClassName {
  background-color: #a1dcff;

}

.triggerClassName {

}

.triggerOpenedClassName {

}

.contentOuterClassName {
  background-color: orange;

}

.contentInnerClassName {
  background-color: white;

}

.ui.inline.dropdown>.text {
  font-weight: 400;
}

/*.ui.dropdown>.dropdown.icon:before {
  color: black;
}
*/
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hierarchy {
  padding-top: 20px;
}

.hierarchymain {
  display:flex;
  align-items:center;
  height:45px;
  background-color:#F3F3F3;
}


.hierarchymain .span1 {
  margin-left:12px;
  color:#0D0D0D;
  font-size:16px;
}

.hierarchymain .span2 {
  margin-left:10px;
  color:#777777;
  font-size:16px;
  font-style:italic;
}

.hierarchymain2 {
  display:flex;
  align-items:center;
  height:45px;
  /*background-color:#F3F3F3;*/
}

.hierarchymain2 .span1 {
  margin-left:20px;
  color:#aaaaaa;
  font-size:16px;
  /*font-style:italic;*/
  /*text-decoration: underline;*/
}

.extrainfo {
  display:flex;
  align-items:center;
  height:45px;
}
.extrainfo .span1 {
  margin-left:10px;
  color:#777777;
  font-size:16px;
  font-style:italic;
  text-decoration: underline;
}

.extrainformation {
  /*display:flex;*/
  /*align-items:center;*/
  /*height:30px;*/
  margin-left:20px;
  color:#777777;
  font-size:16px;
}

.current {
  border-bottom: 4px solid white;
}

.app {
  /*width: 960px;*/
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-size: 100%;
}

.ui.input>input {
  font-family: Arial,Helvetica,sans-serif;
}

.ui.dropdown.frontpage {
  margin-top: 20px;
}

.ui.dropdown.frontpage .menu .item {
  line-height: 20px;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
